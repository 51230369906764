<template>
  <AtroCard class="gap-4">
    <AtroContent shrink direction="col" justify="center" items="center">
      <AtroHeading
        semibold
        class="leading-none text-atro-slate-purple"
        size="sm"
        text="Security Posture"
      />
      <AtroSpan
        class="mt-1 text-atro-gray"
        size="xs"
        text="Overall Security Protection"
      />
    </AtroContent>

    <PostureGauge class="w-full" :score="postureScore" />

    <AtroContent class="gap-3" direction="col">
      <AtroSpan
        semibold
        class="text-atro-gray"
        size="xs"
        text="Score Ingredients"
      />
      <!-- TOOL COVERAGE -->
      <AtroContent class="w-full" items="center" justify="between">
        <AtroContent class="gap-2">
          <AtroIconWrap
            circle
            :class="[
              '!p-[5px] text-white',
              getRiskColor(getScoreRisk(currentFrameworkProgress * 10), 'bg'),
            ]"
            name="shield"
            size="xs"
            type="custom"
          />
          <AtroSpan
            semibold
            class="text-atro-gray"
            size="xs"
            text="Tool Coverage"
          />
        </AtroContent>
        <AtroSpan
          semibold
          class="text-atro-slate-purple"
          size="sm"
          :text="`${currentFrameworkProgress.toString()}%`"
        />
      </AtroContent>

      <!-- TOOL HEALTH -->
      <AtroContent class="w-full" items="center" justify="between">
        <AtroContent class="gap-2">
          <AtroIconWrap
            circle
            :class="[
              'text-white',
              getRiskColor(getScoreRisk(org?.score), 'bg'),
            ]"
            name="wave-pulse"
            size="xs"
            type="custom"
          />
          <AtroSpan
            semibold
            class="text-atro-gray"
            size="xs"
            text="Tool Health"
          />
        </AtroContent>
        <AtroSpan
          semibold
          class="capitalize text-atro-slate-purple"
          size="sm"
          :text="getPostureText(org?.score)"
        />
      </AtroContent>

      <!-- TEAM RISK -->
      <AtroContent class="w-full" items="center" justify="between">
        <AtroContent class="gap-2">
          <AtroIconWrap
            circle
            :class="[
              '!p-[5px] text-white',
              getRiskColor(teamReport?.teamRisk, 'bg'),
            ]"
            name="user-outline"
            size="xs"
            type="custom"
          />
          <AtroSpan
            semibold
            class="text-atro-gray"
            size="xs"
            text="Team Risk"
          />
        </AtroContent>
        <AtroSpan
          semibold
          class="capitalize text-atro-slate-purple"
          size="sm"
          :text="teamReport?.teamRisk"
        />
      </AtroContent>
    </AtroContent>
  </AtroCard>
</template>

<script setup lang="ts">
import { getPostureText, getRiskColor, getScoreRisk } from '@atro/components'

const { org } = useCurrentOrg()
const { currentFrameworkProgress } = useFrameworkLevels()
const { postureScore } = useSecurityPosture()
const { teamReport } = useTeamReport()
</script>
